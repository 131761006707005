<template>
  <div
    class="checklist gap-5 2xl:gap-10 w-full"
    :class="{ 'checklist--collapsed': !!selected }"
  >
    <div class="checklist-list relative">
      <closeIcon
        v-if="!selected"
        class="close_icon absolute top-3.5 right-3.5 w-4 h-4 cursor-pointer text-base-content fill-current stroke-current"
        @click="$emit('close')"
      />
      <div class="checklist-list__header">
        <div class="header__left" v-if="!hideHeader">
          <!-- <search-field placeholder="Search By Activity" /> -->
          <div class="filter-controls">
            <dropdown
              class="w-48 2xl:w-56"
              width="min-content"
              min-width="160px"
              placeholder="Filter Activity"
              @input="(value) => handleFilterActivity(value, 'activity')"
              :config="{ label: 'title', trackBy: 'value' }"
              :options="options.activity"
              :value="getInitialFilterActivity"
              :colors="{
              bg: '#fff',
              text: '#282828',
              border: '#0D69D5',
              svg: '#0D69D5',
            }"
            >
              <template #before>
                <filter-icon />
              </template>
            </dropdown>
          </div>
        </div>
        <div class="header__right">
          <!-- <dropdown
            menu
            placeholder="Collapse All"
            :colors="{
              bg: '#0D69D5',
            }"
          >
            <template #before>
              <collapse-all-icon />
            </template>
          </dropdown> -->
          <div class="flex gap-1">
            <dropdown
              v-if="!fetchingSortOptions"
              class="sort"
              min-width="100px"
              placeholder="Sort"
              style="border: none; border-radius: 16px"
              :config="{ trackBy: 'key', label: 'label' }"
              :options="sortOptions"
              @input="(value) => handleFilterActivity(value, 'sort')"
            >
            </dropdown>
            <div
              class="flex items-center text-white-text cursor-pointer justify-center flex-col rounded-xl bg-primary px-2 hover:opacity-95"
              @click="handleFilterActivity(null, 'sort-by')"
              title="Sort"
            >
              <font-awesome-icon v-if="sortAsc" icon="arrow-up" />
              <font-awesome-icon v-else icon="arrow-down" />
            </div>
          </div>
        </div>
      </div>
      <div class="checklist-list__content">
        <div
          v-if="loading || isLoading"
          class="absolute inset-0 z-10 flex items-center justify-center"
          style="
            background-color: rgba(255, 255, 255, 0.5);
            backdrop-filter: blur(1px);
          "
        >
          <loader />
        </div>
        <checklist-item
          v-for="email in filterActivityData"
          :id="email.id"
          :ref="email.id"
          :key="email.id"
          :item="email"
          @select="selectCard"
          :selected="!!(selected && email.id === selected.id)"
          :compact="!!selected"
          @showEmailAttachments="showEmailAttachments($event)"
        />
        <div v-if="transitionVisible">
          <div v-for="(item, index) in caseLogsData" :key="index">
            <CaseLogs :item="item" />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="selected && details[selected.activity_type]"
      class="checklist-details"
    >
      <component
        :is="details[selected.activity_type]"
        :key="selected.id"
        :dataT="selected"
        @close="deselect()"
      />
    </div>
    <AttachmentsModal
      v-if="!selected || !details[selected.activity_type]"
      :isLoading="loadingStates.attachments"
      modalTitle="Attachments"
      :showSearch="false"
      :lists="attachments"
      ref="email-attachments"
    >
      <template #item="{ item }">
        <div class="text-primary" @click="handleClickAttachments(item)">
          <span class="text-gray-700 font-semibold">{{ item.index }}. </span>
          <span class="text-blue-700"> {{ item.name }}</span>
        </div>
      </template>
    </AttachmentsModal>
  </div>
</template>

<script>
import axios from "@/axios";
import checklistItem from "./components/checklist-item.vue";
import dropdown from "./components/dropdown-base.vue";
import searchField from "@/components/search-field";
import Loader from "@/components/loader/index.vue";
import { mapActions } from "vuex";

// import sortIcon from '@/assets/icons/dashboard/sort.svg';
import sortIcon from "@/assets/icons/dashboard/dropdown-double.svg";
import filterIcon from "@/assets/icons/dashboard/filter.svg";
import collapseAllIcon from "@/assets/icons/dashboard/collapse-all.svg";
import closeIcon from "@/assets/icons/profile-view/close-icon.svg";
import { EventBus } from "@/main.js";

const emailThread = () =>
  import("@/components/dashboard-components/checklist/components/email-thread");

/* Activity Icons */
const emailIcon = () => import("@/assets/icons/dashboard/activity/email.svg");
const callIcon = () => import("@/assets/icons/dashboard/activity/call.svg");
const noteIcon = () => import("@/assets/icons/dashboard/activity/note.svg");
// const replaceIcon = () => import("@/assets/icons/dashboard/activity/replace.svg");
const taskIcon = () => import("@/assets/icons/dashboard/activity/task.svg");

/* Label Icons */
const briefcaseIcon = () =>
  import("@/assets/icons/dashboard/label/briefcase.svg");
const graduateIcon = () =>
  import("@/assets/icons/dashboard/label/graduate.svg");
import { cloneDeep } from "lodash";
const AttachmentsModal = () => import("@shared/components/listModal");
const transitionsIcon = () =>
  import("@/assets/icons/dashboard/activity/transitions.svg");

import CaseLogs from "../checklist/components/case-logs.vue"

export default {
  name: "checklist",
  props: {
    emails: {
      required: true,
      type: Object,
      default: () => {},
    },
    transitionList: {
      required: true,
      type: Array,
      default: () => [],
    },
    initialFilterActivity: {
      required: false,
      type: Object,
      default: () => ({title: "All", value: "all"}),
    },
    isLoading: {
      required: false,
      type: Boolean,
      default: false,
    },
    hideHeader: {
      required: false,
      type: Boolean,
      default: false,
    }
  },
  components: {
    checklistItem,
    dropdown,
    searchField,
    Loader,
    sortIcon,
    filterIcon,
    collapseAllIcon,
    closeIcon,
    AttachmentsModal,
    CaseLogs
  },
  data() {
    return {
      selected: null,
      // emails: [],
      options: {
        // TODO; get options from list
        activity: [
          { title: "Email", value: "Email", icon: emailIcon },
          { title: "Call", value: "Call", icon: callIcon },
          { title: "Note", value: "Note", icon: noteIcon },
          // { title: "Status Change", value: "status_change", icon: replaceIcon },
          { title: "Task", value: "Task", icon: taskIcon },
          {
            title: "Transitions",
            value: "status_changes",
            icon: transitionsIcon,
          },
        ],
        check: [
          { title: "UCL", value: "ucl", icon: graduateIcon },
          { title: "Microsoft", value: "microsoft", icon: briefcaseIcon },
          { title: "Facebook", value: "facebook", icon: briefcaseIcon },
          { title: "Google", value: "google", icon: briefcaseIcon },
        ],
      },
      details: {
        email: emailThread,
      },
      loading: false,
      activityData: this.emails,
      sortOptions: [],
      fetchingSortOptions: false,
      activityFilter: { title: "All", value: "all" },
      activitySortType: null,
      sortAsc: false,
      attachments: [],
      loadingStates: {
        attachments: false,
      },
    };
  },
  computed: {
    actionHandlers() {
      return {
        check: (e) => this.checkEmail(e.data),
        edit: ({ data }) => {
          if (data.type === "tasks") {
            this.$emit("tasksEdit", data);
          }
        },
        delete: ({ data }) => {
          if (data.type === "tasks") {
            this.$emit("tasksDelete", data);
          }
          if (data.type === "note") {
            this.$emit("noteDelete", data);
          }
        },
      };
    },
    filterActivityData() {
      return this.activityData.emails;
    },
    transitionVisible() {
      if(this.activityData?.activity_type === "all" || this.activityData?.activity_type === 'status_changes') {
        return true;
      } else {
        return false;
      }
    },
    caseLogsData() {
      return this.transitionList;
    },
    getInitialFilterActivity() {
      return this.initialFilterActivity;
    }
  },
  async created() {
    EventBus.$on("activityAction", (e) => {
      this.$emit("activityAction", e);
      if (this.actionHandlers[e.type]) this.actionHandlers[e.type](e);
    });
    await this.fetchSortOptions();
  },
  async mounted() {
    this.activityData = this.emails;
  },
  watch: {
    emails: function (val) {
      this.activityData = val;
      this.activityData?.emails?.sort((a, b) => {
        const date1 = a.created_at || a.date;
        const date2 = b.created_at || b.date;
        return new Date(date2) - new Date(date1);
      });
    },
  },
  beforeDestroy() {
    EventBus.$off("activityAction");
  },
  methods: {
    ...mapActions(["fetchCaseNotificationCount", "fetchNotificationCount"]),
    selectCard(card) {
      this.selected = card && card.type === "email" ? card : null;
      if (card?.object_type == "additional_form") {
        this.$emit("redirectToInput", card);
      }
    },
    handleFilterActivity(val, filterType) {
      if (filterType === "activity") {
        this.activityFilter = val ?? this.$emit("setInitialFilterValue");
      } else if (filterType === "sort") {
        this.activitySortType = val;
      } else if (filterType === "sort-by") {
        this.sortAsc = !this.sortAsc;
      }
      this.fetchFilteredActivity();
    },
    async fetchSortOptions() {
      this.fetchingSortOptions = true;
      try {
        let url = `/activity-history/sortkeys`;
        let { data } = await axios.get(url);
        this.sortOptions = data;
      } catch (error) {
        console.log(error, "error>>>>>");
      }
      this.fetchingSortOptions = false;
    },
    async fetchFilteredActivity() {
      let form_data = {
        external_id_type: this.emails.external_id_type,
        external_id: this.emails.external_id,
        activity_type: "all",
        sort_order: this.sortAsc ? "asc" : "desc",
      };
      if (this.activitySortType && this.activitySortType.key) {
        form_data = { ...form_data, sortkey: this.activitySortType.key };
      }
      if (this.activityFilter && this.activityFilter.value) {
        form_data.activity_type = this.activityFilter.value.toLowerCase();
      }
      this.loading = true;
      let url = `/activity-history`;
      try {
        let { data } = await axios.post(url, form_data);
        let filteredemails = data.map((email) => {
          let obj = cloneDeep(email);
          obj.type = email.activity_type;
          switch (email.activity_type) {
            case "email":
              obj.actions = ["reply", "reply-all", "forward", "check"];
              obj.email_type = email.type;
              break;
            case "email_sequence":
              obj.actions = ["expandSequenceBadge"];
              if (obj.email_delivery_status == false) {
                obj.actions.push("check");
              }
              obj.sequence_id = email.master_sequence_id;
              obj.templates = obj.templates.map((x) => ({
                ...x,
                ...{
                  email_from: obj.email_from,
                  email_to: obj.email_to,
                  sequence_id: x.master_sequence_id,
                  html_body: x.body,
                },
              }));
              // obj.email_type = email.type;
              break;
            case "note":
              obj.actions = ["badge", "delete", "check"];
              if (email?.attachments?.length > 0) {
                obj.actions.splice(1, 0, "attachments");
              }
              break;
            case "call":
              obj.actions = ["alarm", "check"];
              break;
            case "tasks":
              obj.actions = ["delete", "edit", "check"];
              break;
            default:
              obj.actions = ["check"];
              break;
          }
          return obj;
        });
        this.activityData = {
          ...this.emails,
          activity_type: form_data.activity_type,
          emails: filteredemails,
        };
      } catch (error) {
        console.log(error, "Error>>");
      }
      this.loading = false;
    },
    deselect() {
      this.selectCard(null);
    },
    async checkEmail(payload) {
      const activityType = {
        email: {
          acknowledge: { url: `email/ack/${payload.id}`, method: "PUT" },
          unacknowledge: {
            url: `email/ack/${payload.id}?ack=false`,
            method: "PUT",
          },
          key: "is_ack",
        },
        email_sequence: {
          acknowledge: { url: `email/ack/${payload.id}`, method: "PUT" },
          unacknowledge: {
            url: `email/ack/${payload.id}?ack=false`,
            method: "PUT",
          },
          key: "is_ack",
        },
        tasks: {
          acknowledge: {
            url: `case-tasks/acknowledge/${payload.id}`,
            method: "POST",
          },
          unacknowledge: {
            url: `case-tasks/unacknowledge/${payload.id}`,
            method: "POST",
          },
          key: "acknowledged",
        },
        note: {
          acknowledge: {
            url: `case-notes/acknowledge/${payload.id}`,
            method: "POST",
          },
          unacknowledge: {
            url: `case-notes/unacknowledge/${payload.id}`,
            method: "POST",
          },
          key: "acknowledged",
        },
      };
      const selCat = activityType[payload.activity_type];
      payload[selCat["key"]] = !payload[selCat["key"]];
      try {
        if (payload[selCat["key"]]) {
          await axios({
            method: selCat["acknowledge"]["method"],
            url: selCat["acknowledge"]["url"],
          });
        } else {
          await axios({
            method: selCat["unacknowledge"]["method"],
            url: selCat["unacknowledge"]["url"],
          });
        }
        this.fetchNotificationCount();
        this.fetchCaseNotificationCount(this.$route?.query["caseid"]);
      } catch (error) {
        console.log(error, "[+] error while acknowledging activity");
      }
      // uncomment if user can't deselect
      // if (payload.is_ack) return;
      // try {
      //     await axios.put(`email/ack/${payload.id}`);
      // } catch (error) {
      //     const email = this.emails.find((el) => el.message_id === payload.id);
      //     email.is_ack = false;
      // }
    },
    // async fetchEmails() {
    //   const case_id = this.$route?.query["caseid"];
    //   let url = `/case/${case_id}/activity-history`;
    //   let { data } = await axios.get(url);
    //   console.log(data, "Data in fetchEmails");
    //   try {
    //     let emailsData = data.map((email) => ({
    //       ...email,
    //       type: "email",
    //       actions: ["reply", "reply-all", "forward", "check"],
    //     }));
    //     this.emails = emailsData;
    //     console.log("this.emails", this.emails);
    //   } catch (error) {
    //     console.log(error, "Error in fetchEmails");
    //   }
    // },

    showEmailAttachments({ data, type }) {
      this.attachments =
        data?.attachments?.map((el, index) => ({
          id: el.key,
          index: index + 1,
          name: el.key.substring(el.key.lastIndexOf("/") + 1),
        })) || [];
      this.$refs[type].showListModal();
    },
    async handleClickAttachments(item) {
      this.loadingStates.attachments = true;
      try {
        let { data } = await axios.get(`/email/attachment/url?key=${item.id}`);
        window.open(data.url, "_blank");
      } catch (error) {
        console.log(error);
      }
      this.loadingStates.attachments = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/functions.scss";

.checklist {
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
  // padding: 0 toRem(20px);
  transition: padding 0.1s linear;
  .checklist-list {
    flex-shrink: 0;
    padding: toRem(40px);
    background-color: #fff;
    border-radius: toRem(24px);
    display: flex;
    flex-direction: column;
    gap: toRem(40px);
    width: 100%;
    max-width: 100%;
    transition: max-width 0.1s ease-out;
    // overflow: hidden;
    &__header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      gap: 20px;
      .header {
        &__left,
        &__right {
          display: flex;
          flex-direction: column;
          gap: 20px;
          .sort {
            @apply bg-primary text-white-text;
          }
        }
        &__left {
          .filter-controls {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
          }
        }
        &__right {
          align-items: flex-end;
        }
      }
    }
    &__content {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 16px;
    }
  }
  &--collapsed {
    padding: 0;
    .checklist-list {
      max-width: toRem(320px);
      padding: toRem(40px) toRem(30px);
      &__header {
        .header {
          &__left {
            width: 100%;
            gap: 16px;
            .filter-controls {
              gap: 16px;
            }
          }
          &__right {
            display: none;
          }
        }
      }
    }
    .checklist-details {
      align-self: stretch;
      flex-grow: 1;
    }
  }
  // .close_icon {
  //     stroke: #282828;
  //     * {
  //         stroke: #282828;
  //     }
  // }
}
</style>
